<template>
  <div class="right-menu shipping-right">
    <subHeader pageName="SEARCH APPEARANCE"/>

    <tutorialsAction />

    <div class="db-blue-section">
      <div class="db-container">        
        <div class="mianBlurBox pt-0">
          <div class="seoDashboardTabsBlock searchAppearance mt-0">
            <!-- tab buttons block starts here -->
            <div class="tabsBtnRow">
              <button @click.prevent="changeTab('content_type')" :class="currentTab == 'content_type' ? 'tab_active' : ''" >Product</button>
            </div>
            <!-- tab buttons block ends here -->

            <!-- search appearance block starts here -->
            <div class="tabsDataBox" v-if="!pending">
              <ValidationObserver ref="NoIndexUpdateObserver" v-slot="{ handleSubmit }">
                <div class="seoDataDashboard" v-show="currentTab == 'content_type'">
                  <!-- <h6 style="color: black">Note: You can use variable [title], [price]</h6> -->
                  <!-- <hr> -->
                  <form @submit.prevent="handleSubmit(saveChanges)">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="formContentBox">
                          <label for="productMetaTitle">Default Product Meta Title</label>
                          <input 
                            v-model="seo.meta_title" 
                            type="text" 
                            name="productMetaTitle" 
                            id="productMetaTitle" 
                            class="form-control"
                            placeholder="Default product meta title"
                            :disabled="tourIsActive"
                            @input="formatHandle('meta_title')"
                          >
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="formContentBox">
                          <label for="productMetaDescripiton">Default Product Meta Descripiton</label>
                          <textarea 
                            v-model="seo.meta_description" 
                            type="text" 
                            name="productMetaDescripiton" 
                            id="productMetaDescripiton"  
                            class="form-control"
                            placeholder="Default product meta description"
                            :disabled="tourIsActive"
                            @input="formatHandle('meta_description')"
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>
                    <div class="dataSaveBtn" style="text-align: right">
                      <button 
                        v-if="!save_btn_loading" 
                        type="submit" 
                        id="save-changes-btn" 
                        :disabled="tourIsActive || ((seo.meta_title == tempSeo.meta_title) && (seo.meta_description == tempSeo.meta_description))"
                      >
                        Save Changes
                      </button>
                      <loaderBtn v-else/>
                    </div>
                  </form>
                </div>
              </ValidationObserver>
            </div>
            <!-- search appearance block starts here -->
              
            <!-- loader block starts here -->
            <div class="tabsDataBox"  v-else>
              <div class="masterToolsRow" style="justify-content: center">
                <vue-skeleton-loader
                  type="rect"
                  :width="1445"
                  :height="415"
                  animation="fade"
                />
              </div>
            </div>  
            <!-- loader block ends here -->

          </div>
        </div>
      </div>
    </div>

    <!--vue tour-->
    <v-tour name="seoSearchAppearanceTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import subHeader from "@/components/subHeader.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
  name: 'Seo',
  metaInfo() {
    return {
      title: 'SEO | Search Appearance | YeetCommerce',
    };
  },
  data(){
    return {
      currentTab: 'content_type',
      seo: {
        meta_title: '',
        meta_description: ''
      },
      tempSeo:{
        meta_title:'',
        meta_description:'',
      },
      save_btn_loading: false,
      //vue-tour
      tourOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip Tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish Tour'
        }
      },
      tourCallbacks: {
        onPreviousStep: this.handlePreviousStep,
        onNextStep: this.handleNextStep,
        // onStart: this.handleTourStart,
        onStop: this.handleTourStop,
        onFinish: this.handleTourFinish,
        onSkip: this.handleTourSkip
      },
      tourSteps:[
        //header steps
        {
          target: '#tour-start-btn',
          content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'bottom'
          }
        },
        {
          target: '#master-search',
          content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
          params: {
            highlight: true,
            enableScrolling: false
          },
        },
        {
          target: '#add-new-dropDown',
          content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#help-link',
          content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#user-options-dropdown',
          content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        //search appearance steps
        {
          target: '#productMetaTitle',
          content: `<strong>Product Meta Title</strong><br>You can input the default meta title for products in this field.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement:'top'
          }
        },
        {
          target: '#productMetaDescripiton',
          content: `<strong>Product Meta Description</strong><br>You can input the default meta description for products in this field.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement:'top'
          }
        },
        {
          target: '#save-changes-btn',
          content: `<strong>Save Changes</strong><br>Added details can be saved by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement:'left'
          }
        },
      ],
      tourIsActive:false,
    }
  },
  components:{
    subHeader,
    tutorialsAction,
    loaderBtn
  },
  methods:{
    changeTab(tab){

      this.currentTab = tab;
      
    },
    formatHandle(field){

      if(field == 'meta_title'){

        let metaTitle = this.seo.meta_title;

        metaTitle = metaTitle.replace(/[^a-zA-Z0-9- ]/g, '');    //eliminate special characters 
        metaTitle = metaTitle.replace(/(\s)\s*/g, '$1');         //keep one space if consecutive spaces have been added
        metaTitle = metaTitle.replace(/(\W)\W*/g, '$1');   

        this.seo.meta_title = metaTitle;

      }else if(field == 'meta_description'){

        let metaDescription = this.seo.meta_description;

        metaDescription = metaDescription.replace(/[^a-zA-Z0-9- ]/g, '');    //eliminate special characters 
        metaDescription = metaDescription.replace(/(\s)\s*/g, '$1');         //keep one space if consecutive spaces have been added
        metaDescription = metaDescription.replace(/(\W)\W*/g, '$1');   

        this.seo.meta_description = metaDescription;

      }

    },
    async saveChanges(){

      this.save_btn_loading = true;
      try{
        let res = await this.$axios.post('/updateProductMetaInfo', this.seo);
        if(res.data.status_code == "1036"){

          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

          this.$store.dispatch('seo_config/fetch_seo_config_Request');

        }
      }catch(error){

        if(error.response){

          if(error.response.data.error.includes("doesn't support")){
            
            let subscriptionPath = window.location.origin + "/subscription";

            this.$message({
              type: 'error',
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: error.response.data.error 
              + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }
        
      }finally{

        this.save_btn_loading = false;

      }
      
    },
    //tour methods
    startSeoSearchAppearanceTour(){

      this.$tours.seoSearchAppearanceTour.start();

      this.tourIsActive = true;

    },
    handleNextStep(currentStep){
    },
    handlePreviousStep(currentStep){
    },
    handleTourStop(){

      this.tourIsActive = false;

    },
    handleTourFinish(){

      this.tourIsActive = false;

    },
    handleTourSkip(){

      this.tourIsActive = false;

    },
  },
  async beforeMount(){

    try{

      if(this.seo_config == null){
      
        this.$store.dispatch('seo_config/fetch_seo_config_Request');

      }else{

        if(this.seo_config.product_meta_title && this.seo_config.product_meta_description){

          this.seo.meta_title = _.cloneDeep(this.seo_config.product_meta_title);
          this.seo.meta_description = _.cloneDeep(this.seo_config.product_meta_description);

          this.tempSeo.meta_title = _.cloneDeep(this.seo_config.product_meta_title);
          this.tempSeo.meta_description = _.cloneDeep(this.seo_config.product_meta_description);

        }else{

          this.seo = {
            meta_title:"",
            meta_description:"",
          }

          this.tempSeo = {
            meta_title:"",
            meta_description:"",
          }

        }

      } 

    }catch(error){

      this.$message({
        type: "error",
        showClose: true,
        message: error.response ? error.response.data.message : error.message,
      });

    }

  },
  computed:{
    ...mapGetters({
      pending: 'seo_config/isPending',
      seo_config: 'seo_config/data',
      error: 'seo_config/error',
      message: 'seo_config/message',
    }),
  },
  watch:{
    seo_config:{
      handler: function(value){

        if(value.product_meta_title && value.product_meta_description){

          this.seo.meta_title = _.cloneDeep(value.product_meta_title);
          this.seo.meta_description = _.cloneDeep(value.product_meta_description);

          this.tempSeo.meta_title = _.cloneDeep(this.seo_config.product_meta_title);
          this.tempSeo.meta_description = _.cloneDeep(this.seo_config.product_meta_description);

        }else{

          this.seo = {
            meta_title:"",
            meta_description:"",
          }

          this.tempSeo = {
            meta_title:"",
            meta_description:"",
          }

        }

      },
      deep: true
    }
  }
}
</script>